import React, { useEffect } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { LazyMotion } from 'framer-motion';
import CssBaseline from '@mui/material/CssBaseline';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import { ThemeProvider } from '@mui/material/styles';
import theme from 'sensortower-components/src/theme';

import type { AppProps } from 'next/app';

// LastRev components
import '../styles/globals.css';
const SEO = dynamic(() => import('sensortower-components/src/base-components/SEO'));

import { start as startBugsnag } from '../src/lib/bugsnag';

type CustomPageProps = {
  pageData?: {
    page?: {
      seo?: {
        title: {
          value: string;
        };
      };
    };
  };
};

type MyAppProps = AppProps<CustomPageProps>;
const loadFeatures = () => import('../src/features').then((res) => res.default);

startBugsnag();

function MyApp(props: MyAppProps) {
  const { Component, pageProps } = props;

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <AppCacheProvider {...props}>
      {pageProps.pageData?.page?.seo ? <SEO seo={pageProps.pageData.page.seo} /> : null}
      <Head>
        {!!pageProps.pageData?.page?.seo?.title ? <title>{pageProps.pageData.page.seo.title.value}</title> : null}
        <meta name="contentful_space" content={process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID} />
        <meta name="contentful_environment" content={process.env.NEXT_PUBLIC_CONTENTFUL_ENV} />
      </Head>

      <ThemeProvider theme={theme}>
        <LazyMotion strict features={loadFeatures}>
          <CssBaseline />
          <Component {...pageProps} />
        </LazyMotion>
      </ThemeProvider>
    </AppCacheProvider>
  );
}

export default MyApp;
