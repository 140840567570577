import { Theme } from '@mui/material';
import { titleTopLineStyle } from '../custom';

// Styles to be shared between variants that show 1, 2, 3, or 4 items per row
const defaultStyles = (itemCount: number, theme: Theme) => {
  const width = Math.round((100 / itemCount + Number.EPSILON) * 10) / 10;

  return {
    // Layout styles
    '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
      justifyContent: 'center',
      '& > [class*="Section-gridItem"]': {
        flex: `0 0 ${width}%`,
        [theme.breakpoints.down('md')]: {
          flex: '0 0 100%',
        },
      },
    },
    // Other styles
    '& [class*="Section-gridItem"] > a': {
      textDecoration: 'none',
    },
    '& [class*="Collection-introText"]': {
      position: 'relative',
      paddingBottom: theme.spacing(5),
      '& h2': {
        paddingBottom: 0,
        color: theme.palette.secondary.contrastText,
        '& b': {
          fontWeight: 700,
        },
        '& strong': {
          fontWeight: 700,
        },
      },
      '& h2 + h2': {
        fontWeight: 700,
        '& + hr': {
          ...titleTopLineStyle(theme),
        },
      },
      '& h3': {
        ...theme.typography.h2,
        color: theme.palette.secondary.contrastText,
      },
      '& h3 + h3': {
        fontWeight: 700,
        '& + hr': {
          ...titleTopLineStyle(theme),
        },
      },
    },
    '& p': {
      color: theme.palette.secondary.contrastText,
    },
  };
};

export const collectionOnePerRowVariant = (theme: Theme) => ({
  props: {
    variant: 'one-per-row',
  },
  style: { ...defaultStyles(1, theme) },
});

export const collectionTwoPerRowVariant = (theme: Theme) => ({
  props: {
    variant: 'two-per-row',
  },
  style: { ...defaultStyles(2, theme) },
});

export const collectionThreePerRowVariant = (theme: Theme) => ({
  props: {
    variant: 'three-per-row',
  },
  style: { ...defaultStyles(3, theme) },
});

export const collectionFourPerRowVariant = (theme: Theme) => ({
  props: {
    variant: 'four-per-row',
  },
  style: { ...defaultStyles(4, theme) },
});

export const collectionTilesVariant = (theme: Theme) => ({
  props: {
    variant: 'tiles',
  },
  style: {
    '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
      justifyContent: 'center',
      '& > [class*="Section-gridItem"]': {
        [theme.breakpoints.down('md')]: {
          flex: '0 0 33.3%',
        },
      },
    },
  },
});

export const collectionIconDarkThreeVariant = (theme: Theme) => ({
  props: {
    variant: 'icon-dark-three',
  },
  style: {
    backgroundColor: theme.palette.product.main,
    // TODO: Figure out a better way to override the default text color (use inherit by default?)
    '& *': {
      color: `${theme.palette.product.contrastText} !important`,
    },
    '& [class*="Collection-introText"]': {
      paddingBottom: theme.spacing(5),
      '& p': {
        maxWidth: 1060,
        margin: '0 auto',
        fontWeight: 400,
      },
    },
    '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
      justifyContent: 'center',

      '& > [class*="Section-gridItem"]': {
        flex: '0 0 33.3%',
        [theme.breakpoints.down('md')]: {
          flex: '0 0 100%',
        },
      },
    },
  },
});

export const collectionIconDarkFourVariant = (theme: Theme) => ({
  props: {
    variant: 'icon-dark-four',
  },
  style: {
    backgroundColor: theme.palette.product.main,
    // TODO: Figure out a better way to override the default text color (use inherit by default?)
    '& *': {
      color: `${theme.palette.product.contrastText} !important`,
    },
    '& [class*="Collection-introText"]': {
      paddingBottom: theme.spacing(5),
      '& *': {
        color: theme.palette.primary.contrastText,
      },
      '& p': {
        maxWidth: 1060,
        margin: '0 auto',
        fontWeight: 400,
      },
    },
    '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
      justifyContent: 'center',

      '& > [class*="Section-gridItem"]': {
        flex: '0 0 25%',
        [theme.breakpoints.down('md')]: {
          flex: '0 0 100%',
        },
      },
    },
  },
});

export const logosVariant = (theme: Theme) => {
  return {
    props: {
      variant: 'logos',
    },
    style: {
      textAlign: 'center',
      color: theme.palette.teal.dark,
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        '& [class*="Collection-contentContainer"]': {
          padding: 0,
        },
      },
      '& [class*="Collection-introText"]': {
        paddingBottom: theme.spacing(5),
        // Make all headers look the same, so it's not necessary for editors to know which one to use
        '& h2, & h3, & h4, & h5, & h6': {
          ...theme.typography.h4,
          fontWeight: 600,
          color: theme.palette.secondary.contrastText,
          '& b, & strong': {
            fontWeight: 600,
          },
        },
        '& h2 + h2, & h3 + h3, & h4 + h4, & h5 + h5, & h6 + h6': {
          fontWeight: 700,
        },
      },
      '& [class*="Section-root"] > [class*="Section-gridContainer"]': {
        [theme.breakpoints.down('lg')]: {
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: theme.spacing(6),
          maxWidth: 'calc(100% - 25vw)',
          margin: '0 auto',
          padding: 0,
          '& > [class*="MuiGrid-item"]': {
            padding: 0,
          },
        },
        [theme.breakpoints.down('md')]: {
          gridGap: theme.spacing(3, 1),
        },
      },
      [theme.breakpoints.down('md')]: {
        '& [class*="MuiGrid-root"]': {
          padding: 0,
        },
      },
      '& [class*="Card-root"]': {
        padding: 0,
      },
      '& img, & [class*="MuiCardMedia-root"]': {
        maxHeight: theme.spacing(4),
        [theme.breakpoints.up('lg')]: {
          maxHeight: theme.spacing(7),
        },
      },
    },
  };
};

export const imageGridVariant = (_theme: Theme) => {
  return {
    props: {
      variant: 'image-grid',
    },
    style: {},
  };
};

export const iconSmallLeftCollectionVariant = (theme: Theme) => {
  return {
    props: {
      itemsVariant: 'icon-small-left',
    },
    style: {
      '& [class*="Collection-introText"]': {
        paddingBottom: theme.spacing(5),
      },
    },
  };
};

const variants = [
  collectionOnePerRowVariant,
  collectionTwoPerRowVariant,
  collectionThreePerRowVariant,
  collectionFourPerRowVariant,
  collectionTilesVariant,
  collectionIconDarkThreeVariant,
  collectionIconDarkFourVariant,
  logosVariant,
  imageGridVariant,
  iconSmallLeftCollectionVariant,
];

const createSectionVariants = (theme: Theme) => {
  return variants.map((creator) => creator(theme));
};

export default createSectionVariants;
