import type { Theme } from '@mui/material/styles';

// Used by mediaLeftTextRightVariant and mediaRightTextLeftVariant
const baseMediaVariant = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  backgroundColor: 'transparent',
  boxShadow: 'none',
  overflow: 'visible',
  color: theme.palette.text.primary,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    '& .MuiButton-root': {
      fontWeight: 700,
      letterSpacing: 0,
      marginBottom: 0,
    },
  },
  '& h3': {
    ...theme.typography.h3,
    fontWeight: theme.typography.fontWeightMedium,
  },
  '& h4': {
    ...theme.typography.h3,
    position: 'relative',
    fontWeight: theme.typography.h4.fontWeight,
  },
  '& img': {
    objectFit: 'contain',
    width: '100%',
    maxWidth: 620,
  },
  '& .MuiCardMedia-root': {
    flex: 1,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      paddingBottom: theme.spacing(3),
    },
  },
  '& .MuiCardContent-root': {
    flex: 1,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(4),
    },
  },
  '& .MuiCardActions-root': {
    padding: 0,
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
      a: {
        width: '100%',
      },
    },
  },
  '& [class*="Text-root"]': {
    marginTop: theme.spacing(3),
    '.MuiTypography-root': {
      paddingBottom: 0,
    },
  },
  '& [class*="introText"]': {
    marginTop: 0,
  },
});

const mediaLeftTextRightVariant = (theme: Theme) => ({
  props: {
    variant: 'media-left-text-right',
  },
  style: {
    ...baseMediaVariant(theme),
    [theme.breakpoints.up('md')]: {
      '& .MuiCardMedia-root': {
        flex: 1,
        // paddingLeft: 0
      },
      '& .MuiCardContent-root': {
        flex: 1,
        paddingLeft: theme.spacing(5),
        // paddingRight: 0
      },
    },
  },
});

const mediaRightTextLeftVariant = (theme: Theme) => ({
  props: {
    variant: 'media-right-text-left',
  },
  style: {
    ...baseMediaVariant(theme),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
      '& .MuiCardMedia-root': {
        flex: 1,
        // paddingRight: 0
      },
      '& .MuiCardContent-root': {
        flex: 1,
        // paddingLeft: 0,
        paddingRight: theme.spacing(5),
      },
    },
  },
});

// Should look similar to resource variant
const blogStandardVariant = (theme: Theme) => ({
  props: {
    variant: 'blog-standard',
  },
  style: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    backgroundColor: 'transparent',

    // Image
    '& .MuiCardMedia-root': {
      flex: 2,

      // This makes the image container have somewhat the same height in all cards,
      // even if the image has a different size
      aspectRatio: '1.5',
      alignItems: 'center',

      [theme.breakpoints.up('xl')]: {
        minWidth: 400,
      },
    },
    '& img': {
      objectFit: 'contain',
      width: '100%',
      borderRadius: 10,
    },

    // Content
    '& .MuiCardContent-root': {
      flex: 3,
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '0px !important',
      paddingLeft: 0,
      paddingRight: 0,
    },
    // Subtitle (inside Card-body)
    '& h5': {
      order: -1,
      color: theme.palette.text.primary,
      letterSpacing: '2.5px',
    },
    // Title (inside Card-body)
    '& h3': {
      color: theme.palette.text.primary,
    },
    // Summary (inside Card-body)
    '& p': {
      color: theme.palette.secondary.contrastText,
    },

    '& .MuiSkeleton-root': {
      transform: 'none',
      WebkitTransform: 'none',
      flex: 1,
      maxWidth: '100%',
    },
    '& .MuiCardActions-root': {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(1),
      paddingBottom: 0,
    },
    '& .MuiButton-root': {
      height: 40,
      minWidth: 210,
      fontSize: 17,
      fontWeight: 600,
      color: theme.palette.text.primary,
      borderColor: theme.palette.primary.main,
    },
    '& .MuiLink-root': {
      textDecoration: 'none',
      WebkitTextDecoration: 'none',
    },
    '& [class*="Text-root"]': {
      flex: 1,
    },
  },
});

const blogVerticalVariant = (theme: Theme) => ({
  props: {
    variant: 'highlight-vertical',
  },
  style: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, -4),
      paddingBottom: theme.spacing(1),
      borderRadius: 0,
    },
    '& h3': {
      marginBottom: 0,
      color: theme.palette.primary.contrastText,
      fontSize: 30,
      fontWeight: 600,
      letterSpacing: '-0.5px',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 31,
        lineHeight: '31px',
      },
    },
    '& h4': {
      marginBottom: theme.spacing(2),
      color: theme.palette.primary.contrastText,
      fontSize: 30,
      fontWeight: 300,
      letterSpacing: '-0.5px',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
        fontSize: 31,
        lineHeight: '31px',
      },
      '&:after': {
        content: "' '",
        height: '1px',
        width: '20px',
        margin: 'auto',
        backgroundColor: '#fff',
        display: 'flex',
        marginTop: theme.spacing(2),
      },
    },
    '& p': {
      textAlign: 'center',
      color: theme.palette.primary.contrastText,
      fontSize: 23,
      fontWeight: 300,
      lineHeight: '140.7%',
      letterSpacing: '-0.5px',
      [theme.breakpoints.down('md')]: {
        fontSize: 21,
        fontWeight: 400,
      },
    },
    '& img': {
      width: '100%',
      objectFit: 'scale-down',
      borderRadius: 10,
      [theme.breakpoints.down('md')]: {
        maxHeight: 500,
      },
    },
    '& .MuiCardMedia-root': {
      margin: theme.spacing(4.5, 4.5, 0),
    },
    '& .MuiCardContent-root': {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(4, 1),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 3),
      },
    },
    '& .MuiCardActions-root': {
      display: 'flex',
      justifyContent: 'center',
      '& .MuiButton-root': {
        width: '100%',
        minWidth: 0,
        height: 42,
        borderRadius: 10,
        fontSize: 16,
        [theme.breakpoints.down('md')]: {
          borderRadius: 25,
        },
      },
    },
  },
});

// Should look similar to blog-standard variant
const resourceVariant = (theme: Theme) => ({
  props: {
    variant: 'resource-standard',
  },
  style: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: 'transparent',

    // Tags displayed on top of the image
    '& [class*="Card-tags"]': {
      position: 'absolute',
      top: theme.spacing(3.5),
      left: 0,
      padding: 0,
      '& .MuiChip-root': {
        backgroundColor: theme.palette.secondary.main,
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 600,
        borderRadius: 0,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        [theme.breakpoints.up('md')]: {
          fontSize: 14,
        },
      },
    },

    // Image
    '& .MuiCardMedia-root': {
      flex: 2,
      height: 227,

      // This makes the image container have somewhat the same height in all cards,
      // even if the image has a different size
      aspectRatio: '1.5',
      alignItems: 'center',

      [theme.breakpoints.up('xl')]: {
        minWidth: 400,
      },

      '& .MuiSkeleton-root': {
        transform: 'none',
        WebkitTransform: 'none',
        flex: 1,
        maxWidth: '100%',
      },
    },
    '& img': {
      objectFit: 'contain',
      width: '100%',
      borderRadius: 10,
    },

    // Content
    '& .MuiCardContent-root': {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '0px !important',
      paddingLeft: 0,
      paddingRight: 0,
    },

    // Subtitle (outside Card-body)
    '& h4': {
      ...theme.typography.h5,
      order: -1,
      color: theme.palette.text.primary,
      letterSpacing: '2.5px',
      paddingBottom: theme.spacing(2),
    },
    // Title (inside Card-body)
    '& h3': {
      color: theme.palette.text.primary,
    },
    // Summary (inside Card-body)
    '& p': {
      color: theme.palette.secondary.contrastText,
    },

    '& .MuiCardActions-root': {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      '& a': {
        width: '100%',
        minWidth: 'auto',
        [theme.breakpoints.down('md')]: {
          letterSpacing: 0,
          fontWeight: 600,
        },
      },
      '& .MuiSkeleton-root': {
        transform: 'none',
        WebkitTransform: 'none',
        width: '100% !important',
        height: 64,
      },
    },
    '& .MuiButton-root': {
      width: '100%',
      minWidth: 'auto',
      [theme.breakpoints.down('md')]: {
        letterSpacing: 0,
        fontWeight: 600,
      },
    },
  },
});

const iconMediumCenterVariant = (theme: Theme) => ({
  props: {
    variant: 'icon-medium-center',
  },
  style: {
    backgroundColor: 'inherit',
    boxShadow: 'none',
    '& .MuiCardContent-root': {
      padding: 0,
    },
    '& h3': {
      marginBottom: theme.spacing(1),
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
    },
    '& p': {
      paddingBottom: '0!important', // Important to override Typography bottom padding
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
    },
    '& .MuiCardMedia-root': {
      paddingBottom: theme.spacing(5),
      '& svg': {
        height: 100,
        [theme.breakpoints.down('md')]: {
          height: 60,
        },
      },
      '& img': {
        height: 100,
        [theme.breakpoints.down('md')]: {
          height: 60,
        },
      },
    },
  },
});

const iconSmallLeftVariant = (theme: Theme) => ({
  props: {
    variant: 'icon-small-left',
  },
  style: {
    display: 'flex',
    // Show icons above content on large screens
    flexDirection: 'column',
    backgroundColor: 'transparent',
    boxShadow: 'none',

    // Show icons on the left of content on small screens
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
    '& h3': {
      marginBottom: theme.spacing(1),
      color: theme.palette.secondary.contrastText,
    },
    '& h4': {
      marginBottom: theme.spacing(3),
      color: theme.palette.secondary.contrastText,
    },
    '& p': {
      color: theme.palette.secondary.contrastText,
    },
    '& .MuiCardMedia-root': {
      justifyContent: 'flex-start',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 0,
      },
      [theme.breakpoints.down('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(0, 2, 2, 0),
      },
      '& svg': {
        height: 72,
        width: 'auto',
        objectFit: 'contain',
        [theme.breakpoints.down('lg')]: {
          width: 39,
          height: 'auto',
        },
      },
      '& img': {
        height: 72,
        width: 'auto',
        objectFit: 'contain',
        [theme.breakpoints.down('lg')]: {
          width: 39,
          height: 'auto',
        },
      },
    },
    '& .MuiCardContent-root': {
      padding: '0',
      paddingBottom: '0!important', // Important to override last-child padding
      paddingTop: theme.spacing(3),
      '[class*="MuiTypography-root"]': {
        paddingBottom: '0',
      },
      [theme.breakpoints.down('lg')]: {
        paddingTop: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(0),
      },
    },
  },
});

const quoteVariant = (theme: Theme) => ({
  props: {
    variant: 'quote',
  },
  style: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    overflow: 'visible',
    backgroundColor: 'transparent',
    borderRadius: 0,
    boxShadow: 'none',
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(5, 0),
    },
    // Image
    '& .MuiCardMedia-root': {
      flex: 1,
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(3),
      },
    },
    '& img': {
      width: '100%',
      objectFit: 'contain',
      [theme.breakpoints.down('md')]: {
        width: '40%',
      },
    },
    '& .MuiCardContent-root': {
      flex: 4,
      display: 'flex',
      flexDirection: 'column-reverse',
      padding: 0,
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    // Name
    '& h3': {
      color: theme.palette.teal.main,
      fontSize: 20,
      lineHeight: 1.5,
      fontWeight: 400,
      position: 'relative',
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    // Quote
    '& p': {
      ...theme.typography.h3,
      color: theme.palette.secondary.main,
      fontWeight: 300,
      lineHeight: 1.4,
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
        lineHeight: '115.2%',
        letterSpacing: '-0.5px',
      },
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(4),
      },
    },
  },
});

const mediaVariant = (theme: Theme) => ({
  props: {
    variant: 'media',
  },
  style: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    '& img': {
      width: '100%',
      objectFit: 'contain',
    },
    '& .MuiCardMedia-root': {
      flex: 1,
    },
    '& .MuiCardContent-root': {
      display: 'none',
    },
  },
});

const reasonVariant = (theme: Theme) => ({
  props: {
    variant: 'reason',
  },
  style: {
    boxShadow: 'none',
    backgroundColor: '#F8F6F6',
    padding: 0,
    borderRadius: 10,
    h3: {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.secondary.main,
      textAlign: 'center',
      padding: theme.spacing(2),
      fontSize: 35,
      [theme.breakpoints.down('md')]: {
        fontSize: 31,
      },
    },
    '& .MuiCardContent-root': {
      padding: 0,
    },
    '& .MuiCardContent-root:last-child': {
      padding: 0,
    },
    '& [class*="Text-root"]': {
      padding: theme.spacing(2),
      '& .MuiTypography-body1': {
        fontSize: 23,
        fontWeight: 200,
        color: theme.palette.grey[900],
        textAlign: 'center',
        padding: theme.spacing(0, 1),
        [theme.breakpoints.down('md')]: {
          fontSize: 19,
        },
      },
      '& ul': {
        marginBlockStart: theme.spacing(1),
        marginBlockEnd: 0,
        listStyle: 'url("./tick.png")',
        '& li:nth-last-of-type(1)::after': {
          width: 0,
        },
      },
      '& li': {
        position: 'relative',
        '& .MuiTypography-body1': {
          textAlign: 'left',
          padding: theme.spacing(1.5, 0),
        },
      },
      '& li::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: -20,
        backgroundColor: theme.palette.text.primary,
        width: '100%',
        height: 1,
      },
    },
  },
});

const insightVariant = (theme: Theme) => ({
  props: {
    variant: 'insight',
  },
  style: {
    backgroundColor: 'transparent',
    borderRadius: 10,
    boxShadow: 'none',
    '& h3': {
      color: theme.palette.text.primary,
    },
    '& h6': {
      ...theme.typography.h3,
    },
    '& h4': {
      display: 'none',
    },
    '& p': {
      paddingBottom: '0!important', // Important to override last-child padding
      color: theme.palette.secondary.contrastText,
    },
    '& .MuiCardMedia-root': {
      position: 'relative',
      width: '100%',
      height: 0,
      padding: '56.25% 0 0',
      overflow: 'hidden',
    },
    '& img': {
      width: '100% !important',
      objectFit: 'cover',
      aspectRatio: '16 / 9',
      borderRadius: 10,
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100% !important',
    },
    // Tags
    '& > .MuiBox-root': {
      display: 'none',
    },
    '& .MuiCardContent-root': {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(5, 0, 0, 0),

      // Actions
      '& > .MuiCardActions-root': {
        paddingTop: theme.spacing(4),
        justifyContent: 'center',
      },
    },
    '& .MuiLink-root': {
      textDecoration: 'none',
      WebkitTextDecoration: 'none',
      '&:hover': {
        textDecoration: theme?.scheme ? 'underline' : 'none',
      },
    },
  },
});

const mediaGridVariant = (theme: Theme) => ({
  props: {
    variant: 'media-grid',
  },
  style: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
    '& img': {
      width: '100%',
      objectFit: 'cover',
    },
    '& .MuiCardMedia-root': {
      flex: 1,
      height: '100%',
    },
    '& .MuiCardContent-root': {
      display: 'none',
    },
  },
});

const tabVariant = (theme: Theme) => ({
  props: {
    variant: 'tab',
  },
  style: {
    boxShadow: 'none',
    // TODO: move to theme
    backgroundColor: '#F8F6F6',
    '& .MuiCardContent-root': {
      padding: 0,
      '& :last-child': {
        paddingBottom: 0,
      },
    },
    '& h3': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2, 0, 0, 1),
      textAlign: 'center',
    },
    '& [class*="Text-root"]': {
      textAlign: 'center',
      padding: theme.spacing(1),
      '& ul': {
        textAlign: 'left',
      },
    },
  },
});

const variants = [
  blogStandardVariant,
  blogVerticalVariant,
  iconMediumCenterVariant,
  iconSmallLeftVariant,
  insightVariant,
  mediaGridVariant,
  mediaLeftTextRightVariant,
  mediaRightTextLeftVariant,
  mediaVariant,
  quoteVariant,
  reasonVariant,
  resourceVariant,
  tabVariant,
];

const createCardVariants = (theme: Theme) => {
  return variants.map((creator) => creator(theme));
};

export default createCardVariants;
