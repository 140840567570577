import { alpha } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import { lighten } from '@mui/system';

const lightVariant = (theme: Theme) => ({
  props: {
    variant: 'marketo-light',
  },
  style: {
    // Wrapper
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 33,
    padding: 50,

    // Loading spinner
    '.MuiCircularProgress-root': {
      color: `${theme.palette.secondary.contrastText} !important`,
    },

    // Intro text
    '& [class*="Text-root"] *': {
      color: `${theme.palette.secondary.contrastText} !important`,
    },

    // Form
    '.mktoForm': {
      minHeight: 300, // The default value set in the Form component is 602px for some reason
      marginLeft: 0,
      padding: '16px 0 !important', // Remove the horizontal padding to align the form with the intro text
    },

    '.mktoFormRow': {
      columnGap: 16, // Spacing between fields in a single row

      '.mktoClear': {
        // Avoid column-gap being added after a single field row
        position: 'absolute',
      },

      // Label
      '.mktoLabel': {
        display: 'block',
        width: 'fit-content !important', // By default labels have 120px width
        marginBottom: `${theme.spacing(1)} !important`,
        fontSize: '16px !important',
        fontWeight: '600 !important',
        color: `${theme.palette.secondary.contrastText} !important`,
      },

      '.mktoGutter, .mktoOffset': {
        display: 'none !important', // Don't use the default 10px gutter/offset to define space between label and field
      },

      // Field
      '.mktoFieldWrap': {
        border: 'none',
        backgroundColor: theme.palette.secondary.main,
        flexDirection: 'column', // Show the label above the field
        alignItems: 'flex-start',
        marginLeft: '0 !important',

        // Input, textarea, and select inputs
        'input, textarea, select': {
          width: '100% !important',
          padding: '10px !important',
          color: `${theme.palette.secondary.contrastText} !important`,
          backgroundColor: `${theme.palette.secondary.light} !important`,
          fontSize: '16px !important',
          fontWeight: 400,
          lineHeight: `1.2 !important`,
          border: '1px solid transparent', // Ensure that the field doesn't shift e.g. when there's an error
          borderRadius: `10px !important`,
          boxShadow: 'none !important',
        },
        // Input and textarea placeholder
        'input::placeholder, textarea::placeholder': {
          color: `${theme.palette.secondary.contrastText} !important`,
          opacity: '0.8 !important',
        },
        '.mktoHtmlText': {
          width: '100% !important',
          p: {
            color: `${theme.palette.secondary.contrastText} !important`,
            textAlign: 'left',
            fontWeight: 400,
          },

          // Privacy policy text at the bottom of the form.
          // Override inline styles set in Marketo.
          '.privacy': {
            p: {
              fontSize: '12px !important',
            },
            a: {
              color: `${theme.palette.teal.main} !important`,
              '&:hover': {
                color: `${lighten(theme.palette.teal.main, 0.4)} !important`,
              },
              // By default it inherits padding from the form
              padding: `0 !important`,
            },
          },
        },
        '&:has(.mktoHtmlText)': {
          marginLeft: 0, // For some reason mktoGutter does not have any effect on this element
        },
      },
      // Make sure that the selector specificity is high enough to win with other styles...
      '.mktoFieldWrap[class*="-select"]': {
        // Select input selected option
        'select.mktoField': {
          color: `${theme.palette.secondary.contrastText} !important`,
          fontWeight: 400,
        },
        // Select input placeholder
        'select.mktoField:has(option[value=""]:checked)': {
          opacity: '0.8 !important',
        },
      },
      // Arrow for select input
      '.mktoFieldWrap[class*="-select"]:after': {
        borderTopColor: theme.palette.secondary.contrastText,
        // Unfortunately, the arrow is after the whole field wrap,
        // not the select input itself, so we can't use e.g. 50%.
        // Additionally, we can't use top, as we don't know the height
        // of the label - it could have one or more rows depending on the
        // content and screen size.
        top: 'unset',
        bottom: 16,
      },
    },
    '.mktoButtonRow': {
      // Button
      marginTop: 24,

      '.mktoButtonWrap, .mktoButtonWrap.mktoDownloadButton': {
        textAlign: 'right',
        marginLeft: '0 !important',

        button: {
          // Font color is different than in sections with teal.main background ¯\_(ツ)_/¯/
          color: `${theme.palette.primary.contrastText} !important`,
          borderRadius: '56px !important',
          backgroundColor: `${theme.palette.teal.main} !important`,
          ':hover': {
            backgroundColor: `${theme.palette.teal.dark} !important`,
          },
          height: 56,
          width: '100%',
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: '18px !important',
          [theme.breakpoints.up('lg')]: {
            fontSize: '24px !important',
          },
        },
      },
    },
  },
});

// TODO: Make it the default variant or delete the default variant
const darkVariant = (theme: Theme) => ({
  props: {
    variant: 'marketo-dark',
  },
  style: {
    // Wrapper
    backgroundColor: theme.palette.blue.main,
    borderRadius: 33,
    padding: 50,

    // Loading spinner
    '.MuiCircularProgress-root': {
      color: `${theme.palette.primary.contrastText} !important`,
    },

    // Intro text
    '& [class*="Text-root"] *': {
      color: `${theme.palette.primary.contrastText} !important`,
    },

    // Form
    '.mktoForm': {
      minHeight: 300, // The default value set in the Form component is 602px for some reason
      marginLeft: 0,
      padding: '16px 0 !important', // Remove the horizontal padding to align the form with the intro text
    },

    '.mktoFormRow': {
      columnGap: 16, // Spacing between fields in a single row

      '.mktoClear': {
        // Avoid column-gap being added after a single field row
        position: 'absolute',
      },

      // Label
      '.mktoLabel': {
        display: 'block',
        width: 'fit-content !important', // By default labels have 120px width
        fontSize: '16px !important',
        fontWeight: '600 !important',
        marginBottom: `${theme.spacing(1)} !important`,
      },

      '.mktoGutter, .mktoOffset': {
        display: 'none !important', // Don't use the default 10px gutter/offset to define space between label and field
      },

      // Field
      '.mktoFieldWrap': {
        border: 'none',
        backgroundColor: `${theme.palette.blue.main} !important`,
        flexDirection: 'column', // Show the label above the field
        alignItems: 'flex-start',
        marginLeft: '0 !important',

        // Text, textarea, and select inputs
        'input, textarea, select': {
          width: '100% !important',
          padding: '10px !important',
          color: `${theme.palette.primary.contrastText} !important`,
          backgroundColor: `${alpha(theme.palette.secondary.main, 0.2)} !important`,
          fontSize: '16px !important',
          fontWeight: 400,
          lineHeight: `1.2 !important`,
          border: '1px solid transparent', // Ensure that the field doesn't shift e.g. when there's an error
          borderRadius: `10px !important`,
          boxShadow: 'none !important',
        },
        // Text input placeholder
        'input::placeholder, textarea::placeholder': {
          color: `${theme.palette.primary.contrastText} !important`,
          opacity: '0.8 !important',
        },
        '.mktoHtmlText': {
          width: '100% !important',
          p: {
            color: `${theme.palette.primary.contrastText} !important`,
            textAlign: 'left',
            fontWeight: 400,
          },

          // Privacy policy text at the bottom of the form.
          // Override inline styles set in Marketo.
          '.privacy': {
            p: {
              fontSize: '12px !important',
            },
            a: {
              color: `${theme.palette.teal.main} !important`,
              '&:hover': {
                color: `${lighten(theme.palette.teal.main, 0.4)} !important`,
              },
              // By default it inherits padding from the form
              padding: `0 !important`,
            },
          },
        },
        '&:has(.mktoHtmlText)': {
          marginLeft: 0, // For some reason mktoGutter does not have any effect on this element
        },
      },
      // Make sure that the selector specificity is high enough to win with other styles...
      '.mktoFieldWrap[class*="-select"]': {
        // Select input selected option
        'select.mktoField': {
          color: `${theme.palette.primary.contrastText} !important`,
          fontWeight: 400,
        },
        // Select input placeholder
        'select.mktoField:has(option[value=""]:checked)': {
          opacity: '0.8 !important',
        },
      },
      // Arrow for select input
      '.mktoFieldWrap[class*="-select"]:after': {
        borderTopColor: theme.palette.primary.contrastText,
        // Unfortunately, the arrow is after the whole field wrap,
        // not the select input itself, so we can't use e.g. 50%.
        // Additionally, we can't use top, as we don't know the height
        // of the label - it could have one or more rows depending on the
        // content and screen size.
        top: 'unset',
        bottom: 16,
      },
    },
    '.mktoButtonRow': {
      // Button
      marginTop: 24,

      '.mktoButtonWrap, .mktoButtonWrap.mktoDownloadButton': {
        textAlign: 'right',
        marginLeft: '0 !important',

        button: {
          // Font color is different than in sections with teal.main background ¯\_(ツ)_/¯/
          color: `${theme.palette.primary.contrastText} !important`,
          borderRadius: '56px !important',
          backgroundColor: `${theme.palette.teal.main} !important`,
          ':hover': {
            backgroundColor: `${theme.palette.teal.dark} !important`,
          },
          height: 56,
          width: '100%',
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: '18px !important',
          [theme.breakpoints.up('lg')]: {
            fontSize: '24px !important',
          },
        },
      },
    },
  },
});

// The same look as the dark variant
const defaultVariant = (theme: Theme) => ({
  props: { variant: 'marketo-default' },
  style: darkVariant(theme).style,
});

// The same look as the dark variant, but the component used is FormInfogramEmbed rather than FormMarketoEmbed.
const infogramVariant = (theme: Theme) => ({
  props: { variant: 'marketo-infogram' },
  style: darkVariant(theme).style,
});

// TODO: Extract layout styles into baseStyles and merge with the variant styles
// All variants should have the same layout, but color schemes should be different
const variants = [lightVariant, darkVariant, defaultVariant, infogramVariant];

const createFormMarketoEmbedVariants = (theme: Theme) => {
  return variants.map((creator) => creator(theme));
};

export default createFormMarketoEmbedVariants;
